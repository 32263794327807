import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import Main from "./components/Main";
import Scan from "./Pages/Scan/Scan";
import Scanner3 from "./Pages/Scanner/QRCodeScanner";
import Settings from "./Pages/Settings/Settings";
import TapToWin from "./Pages/TapToWin/TapToWin";
import Wallet from "./Pages/Wallet/Wallet";
import WinDetails from "./Pages/WinDetails/WinDetails";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
    children: [
      {
        path: "/",
        element: <Scanner3 />,
      },
      {
        path: "/Scan",
        element: <Scan />,
      },
      {
        path: "/Wallet",
        element: <Wallet />,
      },
      {
        path: "/tapToWin",
        element: <TapToWin />,
      },
      {
        path: "/win-details/:campaignId/:memberId",
        element: <WinDetails />,
      },
      {
        path: "/settings",
        element: <Settings />,
      },
    ],
  },
]);

function App() {
  return <RouterProvider router={router}></RouterProvider>;
}

export default App;
