// QRCodeScanner.js
import jsQR from 'jsqr';
import React, { useState, useEffect, useRef } from 'react';
import {useNavigate} from "react-router-dom";

const QRCodeScanner = () => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [scanning, setScanning] = useState(true);
  const [result, setResult] = useState(null);
  const navigate = useNavigate();


  console.log("🚀 ~ file: QRCodeScanner.jsx:10 ~ RESULT:", result)

console.log("process.env.REACT_APP_API_ENDPOINT",process.env.REACT_APP_API_ENDPOINT);

  useEffect(() => {
    const handleScan = async () => {
      const video = videoRef.current;
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');

      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: 'environment' },
        });

        video.srcObject = stream;

        const loop = async () => {
          if (scanning) {
            context.drawImage(video, 0, 0, canvas.width, canvas.height);
            const imageData = context.getImageData(0, 0, canvas.width, canvas.height);

            const code = detectQRCode(imageData);

            if (code) {
              setScanning(false);
              setResult(code);
              localStorage.removeItem('url')
              localStorage.setItem("url", JSON.stringify(code))
              localStorage.setItem("walletChecked", "yes")
              navigate("/tapToWin")
            }

            requestAnimationFrame(loop);
          }
        };

        loop();

      } catch (error) {
        console.error('Error accessing camera:', error);
      }
    };

    handleScan();

    return () => {
      // Cleanup code if needed
    };
  }, [scanning]);

  localStorage.removeItem('walletChecked')

  const detectQRCode = (imageData) => {
    // Use jsQR library to detect QR code from the image data
    const code = jsQR(imageData.data, imageData.width, imageData.height);
    if (code) {
      return code.data;
    }

    return null;
  };

  return (
    <div className="relative hlw">
      {scanning && (
        <video ref={videoRef} className="mx-auto max-w-[400px] object-cover" autoPlay></video>
      )}
      <canvas ref={canvasRef} className="hidden"></canvas>
      {result && 
        <h1 className="text-center">You have successfully scanned! Please go to your wallet.</h1>
      }
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
        <div className="border-2 border-white border-opacity-50 w-48 h-48 mx-auto relative z-10"></div>
      </div>
    </div>
  );
};

export default QRCodeScanner;