import React, { useState } from "react";
import { Link } from "react-router-dom";
import QrIcon from "../../assets/qr.svg";
import wallet from "../../assets/wallet.svg";
import fire from "../../assets/fire.svg";
import settings from "../../assets/settings.svg";

const BottomNavbar = () => {

  const [tt, setTt] = useState("Scan");

  const handleBnOnclick = (e) => {
    const title = e.target.title;
    if(!title){
      setTt(e.target.textContent);
    }else{
      setTt(title);
    }
  }

  return (
    <div className="bottom-nav h-14 fixed bottom-0 bg-white w-full max-w-[400px]">
      <ul className="flex items-center justify-around mt-2">
        <div onClick={handleBnOnclick}>
          <Link to={"/"} className={`text-[#BEBEBE] ${tt === "Scan" ? "bn-active" : ""}`}>
            <img src={tt === "Scan" ? "assets/images/scan.svg" : QrIcon} alt="Wallet Icon" className="mx-auto" title="Scan"/>
            <li className="text-sm" onClick={handleBnOnclick}>Scan</li>
          </Link>
        </div>
        <div onClick={handleBnOnclick}>
          <Link to={"/Wallet"} className={`text-[#BEBEBE] ${tt === "Wallet" ? "bn-active" : ""}`}>
            <img src={tt === "Wallet" ? "assets/images/wallet.svg" : wallet} alt="Wallet Icon" className="mx-auto" title="Wallet"/>
            <li className="text-sm" onClick={handleBnOnclick}>Wallet</li>
          </Link>
        </div>
        <div onClick={handleBnOnclick}>
          <Link to={"/tapToWin"} className={`text-[#BEBEBE] ${tt === "Tap to Win" ? "bn-active" : ""}`}>
            <img src={tt === "Tap to Win" ? "assets/images/tap-to-win.svg" : fire} alt="Wallet Icon" className="mx-auto" title="Tap to Win"/>
            <li className="text-sm" onClick={handleBnOnclick}>Tap to Win</li>
          </Link>
        </div>
        <div onClick={handleBnOnclick}>
          <Link to={"/settings"} className={`text-[#BEBEBE] ${tt === "Settings" ? "bn-active" : ""}`}>
            <img src={tt === "Settings" ? "assets/images/settings.svg" : settings} alt="Wallet Icon" className="mx-auto" title="Settings"/>
            <li className="text-sm" onClick={handleBnOnclick}>Settings</li>
          </Link>
        </div>
      </ul>
    </div>
  );
};

export default BottomNavbar;
