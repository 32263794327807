import React from "react";

const Settings = () => {

  localStorage.removeItem('walletChecked')
  
  return (
    <div className="max-w-screen-sm mx-auto p-4 mt-[100px] bg-gradient-to-t from-blue-300 via-blue-100 to-white">
      <div className="mb-3">
        <label
          htmlFor="email"
          className="block text-sm font-medium text-gray-700"
        >
          Email
        </label>
        <input
          type="email"
          id="email"
          name="email"
          className="mt-1 p-1 w-full border rounded-md"
          placeholder="johndoe@gmail.com"
        />
      </div>

      <div className="mb-3">
        <label
          htmlFor="phone"
          className="block text-sm font-medium text-gray-700"
        >
          Phone
        </label>
        <input
          type="tel"
          id="phone"
          name="phone"
          className="mt-1 p-1 w-full border rounded-md"
          placeholder="+57 3046 6601 89"
        />
      </div>
      <div className="mb-3">
        <label
          htmlFor="name"
          className="block text-sm font-medium text-gray-700"
        >
          Name
        </label>
        <input
          type="text"
          id="name"
          name="name"
          className="mt-1 p-1 w-full border rounded-md"
          placeholder="Armando Coronel"
        />
      </div>

      <div className="mb-3">
        <label htmlFor="id" className="block text-sm font-medium text-gray-700">
          ID
        </label>
        <input
          type="text"
          id="id"
          name="id"
          className="mt-1 p-1 w-full border rounded-md"
          placeholder="36457283"
        />
      </div>
      <div  className="mt-10">
        <button className="bg-blue-500 text-white font-bold h-12 w-full rounded-xl">
          Save
        </button>
      </div>
    </div>
  );
};

export default Settings;
