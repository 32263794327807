import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

export const fetchSpin = createAsyncThunk("spin/fetchSpin",async ()=>{
    const URL = JSON.parse(localStorage.getItem('url'));
    const response = await fetch(URL)
    return await response.json()
})

const spinSlice = createSlice({
    name:"spinSlice",
    initialState:{
        isLoading : false,
        error:null,
        posts:[],
        spinData:{},
        labels:[''],
        colors:[]
    },

    extraReducers:(builder)=>{
        builder.addCase(fetchSpin.pending,(state)=>{
            state.isLoading = true
        }).addCase(fetchSpin.fulfilled,(state, action)=>{
            state.isLoading = false
            state.spinData = action.payload
        }).addCase(fetchSpin.rejected,(state, action)=>{
            state.isLoading = false
            state.error = action.error.message
        })
    }
})

export const {getSpin,setSpin} = spinSlice.actions
export default spinSlice.reducer;