import React, {useEffect} from 'react';
import {useParams} from "react-router-dom";
import {useDispatch,useSelector} from "react-redux";
import {fetchWinning} from "../../features/getWinningDetails";

const WinDetails = () => {

    const {campaignId,memberId} = useParams()
    const dispatch = useDispatch()

    const data = {campaignId,memberId}

    useEffect(() => {
        dispatch(fetchWinning(data))
    }, []);

    const winnings = useSelector((state)=>state.winnings.winnings)

    console.log("Winnings Data",winnings)
    return (
        <div className="h-screen w-full overflow-y-scroll">
            {winnings && winnings.length > 0 ? winnings.map((item,index)=> (
                <div key={index} className="flex items-center justify-between mb-3 p-3 bg-white rounded shadow transition-all hover:bg-yellow-50">
                    <h2 className="font-bold">{item?.name}</h2>
                    <h2 className="font-semibold">{item?.rewards}</h2>
                </div>
            )) : (
                <h1 className="text-center my-5">No Data Found</h1>
            )}

        </div>
    );
};

export default WinDetails;