import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";

export const fetchWallets = createAsyncThunk("getMemberWallets/fetchWallets",async (memberId)=>{
    const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}get-member-wallets`,{
        member_id:memberId
    })
    return response.data
})

const getAllWalletsSlice = createSlice({
    name:"getAllWalletsSlice",
    initialState:{
        isLoading : false,
        error:null,
        wallets:[],
        colors:[]
    },

    extraReducers:(builder)=>{
        builder.addCase(fetchWallets.pending,(state)=>{
            state.isLoading = true
        }).addCase(fetchWallets.fulfilled,(state, action)=>{
            state.isLoading = false
            state.wallets = action.payload.data
        }).addCase(fetchWallets.rejected,(state, action)=>{
            state.isLoading = false
            state.error = action.error.message
        })
    }
})

export default getAllWalletsSlice.reducer;