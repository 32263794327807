import React, {useState, useEffect, Fragment, useRef} from "react";
import {useNavigate , Link} from "react-router-dom";
import Keos from "../../assets/keos.svg";
import BlackQr from "../../assets/qrblack.svg";
import {useDispatch, useSelector} from "react-redux";
// import { setSpinOptions } from '../../features/spinOptionSlice';
// import { setWallet } from '../../features/walletSlice';
// import { setToken } from '../../features/tokenSlice';
import {Dialog, Transition} from '@headlessui/react';
import {ExclamationTriangleIcon} from '@heroicons/react/24/outline';
import {fetchSpin} from "../../features/spinSlice";
import {fetchWallets} from "../../features/getMemberWallets";

const Wallet = () => {
    //const [error, setError] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    //const walletData = useSelector((state) => state.wallets);
    //const tokenData = useSelector((state) => state.token);

    const [open, setOpen] = useState(true)
    const cancelButtonRef = useRef(null)

    const [openWarning, setOpenWarning] = useState(true);
    const [modal, setModal] = useState(false);

    const [noAvailableSpin , setnoAvailableSpin] = useState(false)

    /**
     * API end point: get/hash-by-tenantid
     */
    /*const getHash = async () => {
      await fetch(`${process.env.REACT_APP_API_ENDPOINT}get/hash-by-tenantid`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          "OrderID": walletData?.OrderID,
          "TenantID": walletData?.TenantID,
          "CampaignID": walletData?.CampaignID,
          "ProductID": walletData?.ProductID,
          "PurchaseValue": walletData?.PurchaseValue,
          "email": walletData?.email,
          "is_login": walletData?.is_login
        }),
      })
        .then(response => response.json())
        .then(data => {
          console.log("🚀 ~ getHash ~ RESPONSE:", data)
          localStorage.setItem('hash', JSON.stringify(data.hash));
        })
        .catch(error => {
          console.error("Error:", error);
        });
    }*/

    /**
     * API end point: get-whatsapp-link
     */
    /*const getWhatsappLink = async () => {
      const hash = JSON.parse(localStorage.getItem('hash'));
      await fetch(`${process.env.REACT_APP_API_ENDPOINT}get-whatsapp-link?hash=${hash}`)
        .then(response => response.json())
        .then(data => {
          dispatch(setToken(data));
          console.log("🚀 ~ getWhatsappLink ~ ", data)
        })
        .catch(error => {
          console.warn("Warning:", error);
        });
    };*/


    /**
     * Scanned url API
     */


    // const memberId = 133572206133248;
    //
    // useEffect(() => {
    //     dispatch(fetchWallets(memberId))
    // }, []);


    useEffect(() => {
        dispatch(fetchSpin())
    }, []);

    const walletData = useSelector((state) => state.spin.spinData)

    //console.log('get wallet data ', walletData)


    //const allWallets = useSelector((state) => state.wallets.wallets)
    /*useEffect(() => {
      const scannedUrl = JSON.parse(localStorage.getItem('url'));
      if (scannedUrl) {
        const fetchData = async () => {
          try {
            const response = await fetch(scannedUrl, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              },
            });
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            console.log("🚀 ~ scannedUrl ~ RESPONSE:", jsonData)
            dispatch(setWallet(jsonData));
          } catch (error) {
            setError(error);
          }
        };
        fetchData();
      }
    }, []);*/

    useEffect(() => {
        if (walletData) {
            localStorage.removeItem('walletChecked')
            //getHash();
        }
    }, [walletData]);

    // useEffect(() => {
    //   if (getHash) {
    //     getWhatsappLink();
    //   }
    // }, [getHash]);

    /*const handleOnClick = (orderId, availableSpin, hashId) => {
        if (availableSpin >= 1) {
            // const orderNo = orderId;
            // const selectedData = walletData.find(data => data.OrderID === orderNo);
            //const spinOptions = selectedData.spinner_details.spin_options;
            //dispatch(setSpinOptions(spinOptions));
            localStorage.setItem('walletChecked', 'yes')
            localStorage.setItem('hashId', hashId)
            navigate(`/tapToWin`);
        } else {
            setModal(true);
        }
    }
*/
    // const walletDetails = (availableSpin,hashId) => {
    //     if (availableSpin >= 1) {
    //         localStorage.setItem('hashId', hashId)
    //         localStorage.setItem('walletChecked', 'yes')
    //         navigate(`/tapToWin/${hashId}`);
    //     }else{
    //         setModal(true);
    //     }
    //     console.log('order Id ',availableSpin)
    //     console.log('hash Id ',hashId)
    // }

    const walletDitals = (hashId,availableSpin)=>{
        if (availableSpin>0){
            localStorage.setItem('walletChecked', 'yes')
            navigate(`/tapToWin/${hashId}`)
        }else{
            setnoAvailableSpin(true)
        }
    }

    const dateFormate = (inputDate) => {
        const date = new Date(inputDate);
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: '2-digit'
        });
    }

    const scannedUrl = JSON.parse(localStorage.getItem('url'));
    const handleModalOnClick = () => {
        navigate('/');
    }
    return (
        <div className="">
            {scannedUrl && walletData?.allWalletsData ? walletData?.allWalletsData.map((item, index) => (
                    <div key={index} className='bg-white hover:bg-yellow-50 mb-3 rounded shadow'>
                        <div className="flex flex-row items-center gap-3 px-4 py-2 cursor-pointer w-full" onClick={()=>walletDitals(item?.hashId , item?.availableSpin)}>
                            <div className="w-24 h-24">
                                <img src="https://i.pravatar.cc/800"
                                     alt="keos"
                                     className="w-full h-full  rounded-xl"
                                />
                            </div>
                            <div className="w-full">
                                <div className="flex flex-row items-center justify-between">
                                    <img src={Keos} alt="keos"/>
                                    <p className="text-gray-500 text-sm">{dateFormate(item?.createdAt)}</p>
                                </div>
                                <h1 className="font-bold">{item?.campaingName}</h1>
                                <p className="text-gray-400 text-sm">{item?.memberEmail}</p>
                                <div className="flex flex-row items-center justify-between">
                                    <p className="text-blue-500 font-bold text-sm">Available
                                        Round: {item?.availableSpin}</p>
                                    <img src={BlackQr} alt="qr"/>
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <div className="flex justify-end">
                                <Link to={`/win-details/${item?.campaingId}/${item?.memberId}`} className="px-2 py-1 mb-2 mr-2 rounded bg-blue-500">Win Prize</Link>
                            </div>
                        </div>
                    </div>
                ))
                :
                <Transition.Root show={open} as={Fragment} onClick={handleModalOnClick}>
                    <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                        </Transition.Child>

                        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                            <div
                                className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                >
                                    <Dialog.Panel
                                        className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                        <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                            <div className="sm:flex sm:items-start">
                                                <div
                                                    className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                                                    <ExclamationTriangleIcon className="h-6 w-6 text-green-600"
                                                                             aria-hidden="true"/>
                                                </div>
                                                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                                    <Dialog.Title as="h3"
                                                                  className="text-base font-semibold leading-6 text-gray-900">
                                                        Sorry!
                                                    </Dialog.Title>
                                                    <div className="mt-2">
                                                        <p className="text-sm text-gray-500">
                                                            You do not have any wallets. Scan first.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                            <button
                                                type="button"
                                                className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
                                                onClick={() => {
                                                    setOpen(false);
                                                    handleModalOnClick();
                                                }}
                                            >
                                                Okay
                                            </button>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition.Root>
            }

            {modal &&
                <Transition.Root show={openWarning} as={Fragment}>
                    <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpenWarning}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                        </Transition.Child>

                        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                            <div
                                className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                >
                                    <Dialog.Panel
                                        className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                        <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                            <div className="sm:flex sm:items-start">
                                                <div
                                                    className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                                    &#128552;
                                                </div>
                                                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                                    <Dialog.Title as="h3"
                                                                  className="text-base font-semibold leading-6 text-gray-900">
                                                        Sorry!
                                                    </Dialog.Title>
                                                    <div className="mt-2">
                                                        <p className="text-sm text-gray-500">
                                                            You do not available rounds to spin. Please select another
                                                            wallet to spin...
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                            <button
                                                type="button"
                                                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                                onClick={() => {
                                                    setModal(false);
                                                }}
                                            >
                                                Alright!
                                            </button>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition.Root>
            }

            {noAvailableSpin &&
                <Transition.Root show={open} as={Fragment}>
                    <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-gray-500" />
                        </Transition.Child>

                        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                >
                                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                        <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                            <div className="sm:flex sm:items-start">
                                                <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                                    &#128552;
                                                </div>
                                                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-green-600">
                                                        Sorry .....!!!
                                                    </Dialog.Title>
                                                    <div className="mt-2">
                                                        <p className="text-md font-semibold text-green-300">
                                                            You Have No Available Spin
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                            <button
                                                type="button"
                                                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                                onClick={()=>setnoAvailableSpin(false)}
                                                ref={cancelButtonRef}
                                            >
                                                Alright!
                                            </button>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition.Root>
            }

        </div>
    );
};

export default Wallet;
