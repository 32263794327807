import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

const initialState = {
  labels:[],
  colors:[]
};


const fetchUserData = createAsyncThunk(
    "user/fetchUserData",
    async () => {
      const URL = JSON.parse(localStorage.getItem('url'));
      try {
        const response = await fetch(URL,{
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const data = await response.json()
        console.log(data)
        return data
      }catch (e) {
        console.log(e)
      }
    }
);


const spinOptionSlice = createSlice({
  name: 'spinOptions',
  initialState,
    // reducers: {
    //     // Define your reducer function here
    //     setSpinOptions: (state, action) => {
    //         state.labels = ['new label']
    //         state.colors = ['red','green']
    //         return action.payload;
    //     },
    // },
  extraReducers: (builder) => {
        builder
            .addCase(fetchUserData.pending, (state) => {
                console.log('pending')
            })
            .addCase(fetchUserData.fulfilled, (state, action) => {
                console.log(action.payload)
            })
            .addCase(fetchUserData.rejected, (state, action) => {
                console.log(action.error.message)
            });
  },
});
export const { setSpinOptions } = spinOptionSlice.actions;
export default spinOptionSlice.reducer;