import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    // Define your reducer function here
    setWallet: (state, action) => {
      const data = action.payload;
      const existingIndex = state.findIndex(
        (item) => item.OrderID === data.OrderID
      );
      if (existingIndex !== -1) {
        state[existingIndex] = data;
      } else {
        state.push(data);
      }
    },
  },
});
export const { setWallet } = walletSlice.actions;
export default walletSlice.reducer;