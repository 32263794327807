import { configureStore } from '@reduxjs/toolkit';
import spinOptions from '../features/spinOptionSlice';
import wallets from '../features/getMemberWallets';
import token from '../features/tokenSlice';
import spinSlice from "../features/spinSlice";
import getWhatsappLinkSlice from "../features/getWhatsappLinkSlice";
import getWinningDetailsSlice from "../features/getWinningDetails";
import getWalletDetails from "../features/getWalletDetails";

const store = configureStore({
  reducer: {
    spin:spinSlice,
    whatsappLink:getWhatsappLinkSlice,

    spinOptions: spinOptions,
    wallets: wallets,
    token: token,
    winnings:getWinningDetailsSlice,
    walletDetails:getWalletDetails
  }
});

export default store;