import React from "react";
import BottomNavbar from "./BottomNavbar/BottomNavbar";
import { Outlet } from "react-router-dom";

const Main = () => {
  return (
    <div className="flex flex-col">
      <div className="flex-grow">
        <div className="">
          <Outlet></Outlet>
        </div>
      </div>
      <div className="">
        <BottomNavbar />
      </div>
    </div>
  );
};

export default Main;
