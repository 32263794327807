import React, {useState, Fragment, useRef, useEffect} from "react";
import WheelComponent from "../../components/WheelComponent/WheelComponents";
import { useSelector, useDispatch } from "react-redux";
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { useNavigate } from "react-router-dom";


import { setWallet } from '../../features/walletSlice';
import { setToken } from '../../features/tokenSlice';


import {fetchSpin} from "../../features/spinSlice";
import {fetchWhatsappLink} from "../../features/getWhatsappLinkSlice";
import {fetchWalletDetails} from "../../features/getWalletDetails";


const TapToWin = () => {

  const navigate = useNavigate();
  const [selectedPrize, setSelectedPrize] = useState("");
  const [isWheelDisabled, setWheelDisabled] = useState(false);
  //const spinOptions = useSelector((state) => state.spinOptions.labels);
  //const walletData = useSelector((state) => state.wallets[0]);
  const dispatch = useDispatch();

  //console.log("🚀 ~ walletData:", walletData)
  // const tokenData = useSelector((state) => state.token);
  // console.log("🚀 ~ tokenData:", tokenData);

  const [open, setOpen] = useState(true)
  const cancelButtonRef = useRef(null)
  const [modal, setModal] = useState(false);
  const [spinPlayModal , setSpinPlayModal] = useState(false)

  useEffect(() => {
    dispatch(fetchWalletDetails())
  }, [dispatch]);

  // useEffect(() => {
  //   dispatch(fetchWhatsappLink())
  // }, [dispatch]);


  const walletData = useSelector((state)=>state.walletDetails.walletDetails)

  //const tokenData = useSelector((state)=>state.whatsappLink.tokenData)

  console.log('wallet details',walletData)

  const scannedUrl = JSON.parse(localStorage.getItem('url'));
  const walletChecked = localStorage.getItem('walletChecked');
  const labels = walletData?.spinner_details?.spin_options?.labels
  const colors = walletData?.spinner_details?.spin_options?.colors

  const currentSpin = walletData?.currentSpin


  //console.log('my current spin',walletData)
  const availableSpin = walletData?.spinner_details?.available_spin

  const onFinished = async (winner) => {
    if (availableSpin <= 0) {
      setModal(true);
      return;
    } else {
      setSelectedPrize(winner);
      try {
        const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}update-spinned-rewards/${currentSpin?.id}/${currentSpin?.hashId}`)
        const data = await response.json()
        if (data.success){
          setSpinPlayModal(true)
        }
      }catch (e) {

      }

      // const bodyData = {
      //   "campaign_id": walletData.CampaignID,
      //   "rewards": winner,
      // }
      /*try {
        /!*const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}get/spinned-rewards`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + tokenData.auth_token,
          },
          body: JSON.stringify(bodyData),
        });

        if (response.ok) {
          const data = await response.json();

          // Update walletData in the Redux store
          const updatedWalletData = {
            ...walletData,
            spinner_details: {
              ...walletData.spinner_details,
              available_spin: availableSpin - 1,
            },
          };

          // Update tokenData in the Redux store
          const updatedTokenData = {
            ...tokenData,
            available_spin: availableSpin - 1,
          };

          dispatch(setWallet(updatedWalletData));
          dispatch(setToken(updatedTokenData));
          setWheelDisabled(false);
          console.log("🚀 spinned-rewards RESPONSE:", data);
        } else {
          console.error("Error:", response.statusText);
        }*!/

      } catch (error) {
        console.error("Error:", error);
      }*/
    }
    setWheelDisabled(false);
  };

  const closePlaySpinModal = ()=>{
    window.location.reload();
    setSpinPlayModal(false)
  }

  const handleOnClick = () => {
    navigate('/');
  }

  return (
    <>
      {
          walletChecked === "yes" ? scannedUrl ?
          <div className="h-full flex flex-col items-center justify-center overflow-hidden">
            <div id="wheelCircle ">
              {labels && labels.length > 0 && (
                  <WheelComponent
                      segments={labels}
                      segColors={colors}
                      winningSegment={currentSpin?.rewards}
                      onFinished={(winner) => onFinished(winner)}
                      primaryColor="black"
                      primaryColoraround="#ffffffb4"
                      contrastColor="white"
                      buttonText="Spin"
                      isOnlyOnce={false}
                      size={190}
                      upDuration={40}
                      downDuration={1000}
                      isWheelDisabled={isWheelDisabled}
                  />
              )}
            </div>
            <h1 className="font-bold">{selectedPrize}</h1>
          </div> :
          <Transition.Root show={open} as={Fragment} onClick={handleOnClick}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                      <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                        <div className="sm:flex sm:items-start">
                          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                            <ExclamationTriangleIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                          </div>
                          <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                            <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                              Sorry!
                            </Dialog.Title>
                            <div className="mt-2">
                              <p className="text-sm text-gray-500">
                                You do not have any wallets to spin.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
                          onClick={() => { setOpen(false); handleOnClick(); }}
                        >
                          Okay
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root> :
          <Transition.Root show={open} as={Fragment} onClick={() => { navigate('/Wallet'); }}>
          <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                      <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                          <ExclamationTriangleIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                        </div>
                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                          <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                            Sorry!
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="text-sm text-gray-500">
                              Please select a wallet first!
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
                        onClick={() => { setOpen(false); navigate('/Wallet'); }}
                      >
                        Okay
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      }

      {modal &&
        <Transition.Root show={open} as={Fragment}>
          <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                      <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                          &#128552;
                        </div>
                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                          <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                            Sorry!
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="text-sm text-gray-500">
                              You do not available rounds to spin. Please select another wallet and spin...
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        onClick={() => { setOpen(false); navigate('/Wallet') }}
                        ref={cancelButtonRef}
                      >
                        Alright!
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      }

      {spinPlayModal &&
          <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
              <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-500" />
              </Transition.Child>

              <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      enterTo="opacity-100 translate-y-0 sm:scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                      leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                      <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                        <div className="sm:flex sm:items-start">
                          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                            &#128552;
                          </div>
                          <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                            <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-green-600">
                              Congratulations .....!!!
                            </Dialog.Title>
                            <div className="mt-2">
                              <p className="text-md font-semibold text-green-300">
                                Congratulations your spin result is <span className="px-3 py-1 bg-green-100 text-gray-500 rounded">{selectedPrize}</span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                        <button
                            type="button"
                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                            onClick={closePlaySpinModal}
                            ref={cancelButtonRef}
                        >
                          Alright!
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
      }
    </>
  );
};

export default TapToWin;
