import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";

export const fetchWalletDetails = createAsyncThunk("walletDetails/fetchWalletDetails",async (hashId)=>{
    const URL = JSON.parse(localStorage.getItem('url'))

    const totalURL = `${URL}?app_token=keos_app`
    console.log(totalURL)
    const response = await axios.get(totalURL)
    return response.data
})

const getWalletDetails = createSlice({
    name:"getWalletDetails",
    initialState:{
        isLoading : false,
        error:null,
        walletDetails:[],
        colors:[]
    },

    extraReducers:(builder)=>{
        builder.addCase(fetchWalletDetails.pending,(state)=>{
            state.isLoading = true
        }).addCase(fetchWalletDetails.fulfilled,(state, action)=>{
            state.isLoading = false
            state.walletDetails = action.payload
        }).addCase(fetchWalletDetails.rejected,(state, action)=>{
            state.isLoading = false
            state.error = action.error.message
        })
    }
})



export default getWalletDetails.reducer;