import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import swDev from "./swDev";
import { Provider } from 'react-redux';
import store from './app/store';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Provider store={store}>
        <div className="ma max-w-[400px] overflow-x-hidden mx-auto">
            <App />
        </div>
    </Provider>
);
swDev();
reportWebVitals();
