import React from "react";
import WalletIcon from "../../assets/walletIcon.svg";

const Scan = () => {
  return (
    <div className="w-screen flex flex-col items-center justify-center">
      <div className="flex flex-col items-center">
        <img src={WalletIcon} alt="Wallet Icon" />
        <div className="mt-8">
          <button className="bg-[#255AE8] text-white font-bold h-12 w-28 rounded-xl">
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default Scan;
