import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";

export const fetchWinning = createAsyncThunk("winningDetails/fetchWinning",async ({campaignId,memberId})=>{

    const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}get-winning-rewards`,{
        campaign_id:campaignId,
        member_id:memberId
    })

    return response.data
})

const getWinningDetailsSlice = createSlice({
    name:"getWinningDetailsSlice",
    initialState:{
        isLoading : false,
        error:null,
        winnings:[],
        colors:[]
    },

    extraReducers:(builder)=>{
        builder.addCase(fetchWinning.pending,(state)=>{
            state.isLoading = true
        }).addCase(fetchWinning.fulfilled,(state, action)=>{
            state.isLoading = false
            state.winnings = action.payload
        }).addCase(fetchWinning.rejected,(state, action)=>{
            state.isLoading = false
            state.error = action.error.message
        })
    }
})

export default getWinningDetailsSlice.reducer;