import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

export const fetchWhatsappLink = createAsyncThunk("whatsappLink/fetchWhatsappLink",async ()=>{
    const HASH = JSON.parse(localStorage.getItem('hash'));
    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}get-whatsapp-link?hash=${HASH}`)
    return await response.json()
})

const getWhatsappLink = createSlice({
    name:"whatsappLink",
    initialState:{
        isLoading : false,
        error:null,
        tokenData:{},
    },

    extraReducers:(builder)=>{
        builder.addCase(fetchWhatsappLink.pending,(state)=>{
            state.isLoading = true
        }).addCase(fetchWhatsappLink.fulfilled,(state, action)=>{
            state.isLoading = false
            state.tokenData = action.payload
        }).addCase(fetchWhatsappLink.rejected,(state, action)=>{
            state.isLoading = false
            state.error = action?.error.message
        })
    }
})

export default getWhatsappLink.reducer;